import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout/layout";
import LandingComponent from "../components/Landing/landing.Component";
import config from "../../data/SiteConfig";
import customerOnBoarding from "../store/customerOnBoarding";

export default function Landing(props) {
    
    return (
      <Layout title="Customer">
        <div className="index-container">
          <Helmet>
            <title>{`Customer | ${config.siteTitle}`}</title>
            <link rel="canonical" href={`${config.siteUrl}/index/`} />
          </Helmet>
          <div className="loader"></div>
          <LandingComponent customerOnBoarding={customerOnBoarding}/>
        </div>
      </Layout>
    );
}

